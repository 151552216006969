<template>
  <div>
    <h1>About</h1>
    <p>The world seems to be a bit crazy at times. <b>Is it Babylon Bee?</b> aims to see how well you can distinguish between satire and real life by showing you article titles one at a time and seeing if you can tell if they are from <a href="https://babylonbee.com/" target="_blank" rel="noopener noreferrer">Babylon Bee</a> (a popular Christian satire site) or not.</p>
    <p><b>Is it Babylon Bee?</b> is not associated with Babylon Bee or any of the news sites linked from this site. Links to referenced articles are available after you make your guess.</p>

    <h2>Beta Version</h2>
    <p>This site is currently in its beta version. Please feel free to suggest articles to be added to the site or any feedback you may have for the site using the links in the footer below.</p>

    <h2>Support This Site</h2>
    <p>You can help keep this site running by buying a virtual coffee on <a href="https://ko-fi.com/isitbabylonbee" target="_blank" rel="noopener noreferrer">Ko-fi</a>!</p>
  </div>
</template>

<script>
const title = 'Is it Babylon Bee? | About';

export default {
  beforeRouteEnter(to, from, next) {
    document.title = title;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = title;
    next();
  }
}
</script>
